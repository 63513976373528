import React, { FC } from 'react';
import styled from 'styled-components';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker, { RangePickerProps as AntdRangePickerProps, PickerProps } from 'antd/lib/date-picker/generatePicker';
import en_GB from 'antd/lib/date-picker/locale/en_GB';
import { getWeek, getWeekYear, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import theme from 'Shared/constants/theme';

const GeneratedDatePicker = generatePicker<Date>(dateFnsGenerateConfig);

type AntdRangePickerDateProps = AntdRangePickerProps<Date>;
type AntdDatePickerDateProps = PickerProps<Date>;

const {
  RangePicker: AntdRangePicker,
} = GeneratedDatePicker;

type DatePickerStyleProps = {
  block?: boolean;
};

type RangePickerProps = AntdRangePickerDateProps & DatePickerStyleProps;
type DatePickerProps = AntdDatePickerDateProps & DatePickerStyleProps;

// function styleDatePicker<TPickerProps extends DatePickerStyleProps>(
//   picker: ComponentClass<TPickerProps>,
// ): any {
//   const StyledDatePicker = styled<ComponentClass<TPickerProps>>(picker)(
//     (props: TPickerProps) => ({
//       display: props.block ? 'flex' : 'inline-flex',
//     }),
//   );
//   return StyledDatePicker;
// }

// @todo refactor this later
// can't find out how to set up correct types via generic styleDatePicker
const StyledRangePicker: FC<RangePickerProps> = styled(AntdRangePicker)(
  (props: RangePickerProps) => ({
    display: props.block ? 'flex' : 'inline-flex',
  }),
);

const StyledDatePicker: FC<DatePickerProps> = styled(GeneratedDatePicker)(
  (props: DatePickerProps) => ({
    display: props.block ? 'flex' : 'inline-flex',
  }),
);

function getDateFormat(datePicker: AntdDatePickerDateProps['picker']): AntdDatePickerDateProps['format'] {
  switch (datePicker) {
    case 'month':
      return [theme.dateFormats.monthAndYear];
    case 'week':
      return (value) => `Week ${getWeek(value)}, ${getWeekYear(value)}`;
    default:
      return [theme.dateFormats.humanReadable, theme.dateFormats.DateMonthYear];
  }
}

// in en_GB locale Monday is the first day in calendar view
const RangePicker: FC<RangePickerProps> = (props) => {
  return (
    <StyledRangePicker
      locale={en_GB}
      format={getDateFormat(props.picker)}
      {...props}
      onChange={(values, formatString) => {
        if (!props.onChange) return;

        // reset date to the start of the month and day, i.e 01.10.2020 00:00:00 time
        if (props.picker === 'month') {
          props.onChange(
            [
              values?.[0] ? startOfMonth(values[0]) : null,
              values?.[1] ? startOfMonth(values[1]) : null,
            ],
            formatString
          );
          return;
        }

        // reset date to the start of the week and day, i.e 01.10.2020 00:00:00 time
        if (props.picker === 'week') {
          props.onChange(
            [
              values?.[0] ? startOfWeek(values[0], { weekStartsOn: 1 }) : null,
              values?.[1] ? startOfWeek(values[1], { weekStartsOn: 1 }) : null,
            ],
            formatString
          );
          return;
        }

        // reset startDate and endDate to the start of the day, i.e 00:00:00 time
        props.onChange(
          [
            values?.[0] ? startOfDay(values[0]) : null,
            values?.[1] ? startOfDay(values[1]) : null,
          ],
          formatString
        );
      }}
    />
  );
};

const DatePicker: FC<DatePickerProps> = (props) => {
  return (
    <StyledDatePicker
      locale={en_GB}
      format={getDateFormat(props.picker)}
      {...props}
      onChange={(value, formatString) => {
        if (!props.onChange) return;

        // reset date to the start of the month and day, i.e 01.10.2020 00:00:00 time
        if (props.picker === 'month') {
          props.onChange(value ? startOfMonth(value) : null, formatString);
          return;
        }

        // reset date to the start of the week and day, i.e 01.10.2020 00:00:00 time
        if (props.picker === 'week') {
          props.onChange(value ? startOfWeek(value, { weekStartsOn: 1 }) : null, formatString);
          return;
        }

        // reset date to the start of the day, i.e 00:00:00 time
        props.onChange(value ? startOfDay(value) : null, formatString);
      }}
    />
  );
};



export {
  DatePicker,
  RangePicker,
};

export type {
  DatePickerProps,
  RangePickerProps,
};